* {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Regular", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  --black: #202c33;
  --blackgray: #5B5B5B;
  --bluedark: #313850;
  --blue: #25BDBE;
  --blueline: #2ce2e2;
  --blueshadow: #8F97B4;
  --blueshtrans: #8f97b49f;
  --branco: #ffffff;
  --cinza: #FAFAFA;
  --easygreen: #5fae85;
  --easyred: #f91e1e;
  --lRed: #f45170;
  --lineblue: #002ab7;
  --linecolor: #D9D9D9;
  --logoGreen: #78E75A;
  --logoShadow: #5EC363;
  --lYellow: #FFE350;
  --offblue: #E9EEFF;
  --offblueshadow: #ccd7fa;
  --offgreen: #e1ffe1;
  --offred: #fff6f6;
  --offverde: #E0FFDF33;
  --offyellow: #FFFACD;
  --roxo: #c68de0;
  --text: #3c3c3c;
  --verde: #7DB87B;
  --yellow: #FAE849;
  --shadow: 0px 0px 6px -3px black;
  --lineargradiente: linear-gradient(var(--blueline) 1px, transparent 0), linear-gradient(90deg, var(--blueline) 1px, transparent 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.retention {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.retent {
  width: 100%;
  max-width: 1100px;
}