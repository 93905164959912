.topHeader {
  position: relative;
  width: 100%;
  height: auto;
  padding: 16px 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--linecolor);
}

.topHeader img {
  height: 30px;
}
/* .topHeader nav {} */
button {
  background-color: transparent;
  border: 0;
  padding: 14px 24px;
  border-radius: 5px;
  font-weight: 900;
  cursor: pointer;
  font-size: 14px;
}
button.simple {
  color: var(--text);
}
button.complete {
  color: var(--branco);
  background-color: var(--blue);
}

.hero {
  position: relative;
  width: 100%;
  background-color: var(--blue);
  padding: 20px 7%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  min-height: 500px;
  height: auto;
}

.text h1 {
  color: var(--branco);
  font-size: 56px;
  width: 100%;
  line-height: 60px;
  margin-bottom: 20px;
}

.text h2 {
  color: var(--branco);
  font-size: 36px;
  width: 100%;
  line-height: 42px;
  margin-bottom: 10px;
}

 .text {
  max-width: 500px;
}
.text p {
  color: var(--branco);
  font-size: 20px;
  line-height: 28px;
  max-width: 500px;
  margin-bottom: 20px;
}
.text span {
  color: var(--branco);
}
.formHero {
  background-color: var(--branco);
  width: auto;
  position: relative;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
}

.formHero input {
  width: 100%;
  height: 40px;
  border: 0;
  background: transparent;
  font-size: 16px;
  padding: 0px 18px;
  border-radius: 5px;
}
.hero .img {
  position: relative;
  max-width: 500px;
  margin-bottom: -24px;

}
.hero .img img {
  position: relative;
  width: 100%;
}
.ContainerHorizontal {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 30px 5%;
  background-color: var(--cinza);
}

.ContentIconesHorizontal {
  max-width: 340px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 10px;
}
.ContentIconesHorizontal h2 {
  font-size: 16px;
  margin-bottom: 10px;
}
.ContentIconesHorizontal p {
  font-size: 12px;
  line-height: 18px;
}

.ContentIconesHorizontal img {
  margin-right: 16px;
}

.ExperimenteDiv {
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.yellowDiv {
  background-color: var(--lYellow);
  border-radius: 20px;
  padding: 20px;
  margin-right: -40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.yellowDiv .text {
  padding: 20px;
  margin-right: 20px;
}
.yellowDiv .text h1 {
  font-size: 32px;
  line-height: 40px;
  color: var(--text);
  font-weight: 800;
}
.yellowDiv .text p {
  font-size: 16px;
  line-height: 24px;
  color: var(--text);
}

.yellowDiv .text span {
  font-size: 12px;
  line-height: 18px;
  color: var(--text);
}

.yellowDiv img {
  margin-right: -20%;
}

.BenficiosGerais {
  position: relative;
  width: 100%;
  min-height: 400px;
  background-color: var(--blue);
  background-image: var(--lineargradiente);
  background-size: 30px 30px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.divisor {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;
}

.divisor .text {
  padding: 40px;
}

.rigthImg {
    /* margin-right: -200px; */
    width: 100%;
    max-width: 450px;
    border-radius: 42px;
}

.leftImg {
  width: 100%;
  max-width: 450px;
  border-radius: 42px;
}

.DivApi {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.DivApi img {
  width: 100%;
  max-width: 400px;
}
.DivApi .text h2 {
  color: var(--text);
  margin-top: 10px;
}
.DivApi .text p {
  color: var(--text);
}
.DivApi .text span.destaque {
  color: var(--branco);
  border-radius: 50px;
  font-weight: bold;
  text-align: left;
  line-height: 28px;
  background-color: var(--blue);
  padding: 6px 12px;
}
.DivApi .text a {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
}

.ChamadaFinal{
  padding: 20px 14%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ChamadaFinal .yellowDiv {
  margin: 0;
  padding: 40px;
  width: 100%;
}

.ChamadaFinal .yellowDiv strong {
  color: var(--blue);
}
.ChamadaFinal .yellowDiv p {
  font-size: 22px;
}
.vazio {
  background-color:var(--blue);
  font-weight: 900;
  border-radius: 5px;
  color: var(--branco);
  font-size: 18px;
  cursor: pointer;
}

.NumerosAtingidos {
  border-top: 1px solid var(--linecolor);
  border-bottom: 1px solid var(--linecolor);
}
.NumerosAtingidos .ContentIconesHorizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.NumerosAtingidos .ContentIconesHorizontal .ContentIconesHorizontal_text p {
  display: none;
}

.NumerosAtingidos .ContentIconesHorizontal img {
  margin-right: 16px;
  height: 60px;
}

.NumerosAtingidos .ContentIconesHorizontal h2 {
  color: var(--blackgray);
}
footer {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 40px;
  color: var(--blackgray);
  font-size: 14px;
}